import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-test.azure-api.net/api',
  keycloakUrl: 'https://test.log.wien/auth/',
  keycloakRealm: 'admin',
  keycloakClient: 'client-individualkundenportal-admin-public',
  baseUrl: 'https://ikpberaterfrontendtest.kinsta.cloud',
  staticContentUrl: 'https://static.wienenergie.at/projects/premiumserviceportal/config',
  impersonationUrl: 'https://test.log.wien/kunden-service-app/',
};

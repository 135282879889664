export const ROUTE_HOME = 'home';
export const ROUTE_STARTPAGE = '/home';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';

// Utility function for relative paths
export function routePrefix(route: string) {
  return '/' + route;
}

// Utility function for full URL paths
export function fullRoutePrefix(route: string) {
  // check if route already starts with /
  if (route.startsWith('/')) {
    return window.location.origin + route;
  }

  return window.location.origin + '/' + route;
}

export function homeRoutePrefix(route: string) {
  return '/' + ROUTE_HOME + routePrefix(route);
}

import { Route, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ROUTE_LOGOUT_SUCCESS, ROUTE_HOME, ROUTE_CUSTOMER_REGISTRATION } from './app.constants';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { authGuard } from '@shared/auth/guard/auth.guard';
import { beraterGuard } from '@berater/guards/berater.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    component: FrontpageComponent,
    children: [],
  },
  {
    path: ROUTE_LOGOUT_SUCCESS,
    component: FrontpageComponent,
  },
  {
    path: ROUTE_HOME,
    loadChildren: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES),
    canActivate: [authGuard, beraterGuard],
  },
  {
    path: ROUTE_CUSTOMER_REGISTRATION,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'Registration',
    },
    loadChildren: () => import('./pages/registration/registration.module').then(mod => mod.RegistrationModule),
    canActivate: [authGuard, beraterGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES.find(route => route.path === '').component),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AccountLogic, AccountStateService, EnvironmentService } from '@mwe/services';
import { APPLICATION_NAME, DEFAULT_POPUP_COMPONENT_TOKEN, LOCATION_TOKEN } from '@mwe/constants';
import { LocalStorageService, provideNgxWebstorage, withLocalStorage, withSessionStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { OAuthModule, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { createTranslateLoader } from '@mwe/utils';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { PopupComponent } from '@mwe/ui/components';
import { BeraterEnvironmentService } from '@berater/service/environment/berater-environment.service';
import { keycloakConfig } from '@berater/config/keycloak.config';
import { authInterceptor } from '@berater/interceptor/auth.interceptor';

function initKeycloak(keycloak: KeycloakService) {
  return async () => {
    await keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'berater', separator: '_', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage(),
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      OAuthModule.forRoot(),
      KeycloakAngularModule,
    ),
    provideHttpClient(withInterceptors([authInterceptor])),
    { provide: APPLICATION_NAME, useValue: 'MWE' },
    { provide: LOCATION_TOKEN, useValue: 'local' },
    { provide: LocalStorageService, useClass: LocalStorageService },
    { provide: AccountStateService, useClass: AccountStateService },
    { provide: EnvironmentService, useClass: BeraterEnvironmentService },
    { provide: AccountLogic, useClass: AccountLogic },
    { provide: OAuthService, useClass: OAuthService },
    { provide: UrlHelperService, useClass: UrlHelperService },
    { provide: DEFAULT_POPUP_COMPONENT_TOKEN, useValue: PopupComponent },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
};

import { Injectable } from '@angular/core';
import { environment } from '@berater/environments/environment';
import { Environment } from '@berater/environments/model/environment.model';

@Injectable({
  providedIn: 'root',
})
export class BeraterEnvironmentService {
  private environment: Environment;

  constructor() {
    this.environment = environment;
  }

  getApiUrl(): string {
    return this.environment.apiUrl;
  }

  getBaseUrl(): string {
    return this.environment.baseUrl;
  }

  getKeycloakUrl(): string {
    return this.environment.keycloakUrl;
  }

  getStaticContentUrl() {
    return this.environment.staticContentUrl;
  }

  getImpersonationUrl(): string {
    return this.environment.impersonationUrl;
  }

  getLoggingAPIOBaseURL(): string {
    return '';
  }

  isProduction(): boolean {
    return this.environment.production;
  }
}

import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { BeraterService } from '@berater/service/berater/berater.service';
import { IkpPopupService } from '@berater/service/popup/ikp-popup.service';
import { IPopupModel } from '@mwe/models';
import { switchMap, take } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { IkpAuthService } from '@berater/service/auth/ikp-auth.service';

const forbiddenPopup: IPopupModel = {
  id: 'verify-berater-failed',
  titleKey: 'global.errors.forbidden.title',
  messageKey: 'global.errors.forbidden.errorMessage',
  showSubmitButton: true,
  submitButtonKey: 'global.errors.forbidden.buttonText',
  dismissable: false,
};

const genericErrorPopup: IPopupModel = {
  id: 'verify-berater-failed',
  titleKey: 'global.errors.berater.title',
  messageKey: 'global.errors.berater.errorMessage',
  showSubmitButton: true,
  submitButtonKey: 'global.errors.berater.buttonText',
  dismissable: false,
};

async function checkVerifiedBerater() {
  const beraterService = inject(BeraterService);
  const popupService = inject(IkpPopupService);
  const authService = inject(IkpAuthService);
  return await beraterService
    .getBeraterInfoAboutCurrentUser()
    .then(() => {
      return true;
    })
    .catch((error: HttpErrorResponse) => {
      const popup = error.status === 403 ? forbiddenPopup : genericErrorPopup;
      popupService.handleGenericPopup(popup);
      popupService
        .events()
        .pipe(
          take(1),
          switchMap(() => fromPromise(authService.logout())),
        )
        .subscribe();
      return false;
    });
}

export const beraterGuard: CanActivateFn = checkVerifiedBerater;

export const ROUTE_HOME = 'home';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';
export const ROUTE_STARTPAGE = '/home';
export const ROUTE_CUSTOMER_REGISTRATION = 'kunden-registrierung';
export const ROUTE_COMPANY_SELECT = 'unternehmen-auswahl';
export const ROUTE_COMPANY_CHECK = 'unternehmen-pruefung';
export const ROUTE_AGENT_SELECTION = 'berater-auswahl';
export const ROUTE_INVITE = 'einladen';
export const ROUTE_THANK_YOU = 'danke';

// Pagination
export const PAGE_SIZE = 10;

// API
export const API_ROUTE_SEARCH_COMPANIES_OPEN_FOR_INVITATION = 'unternehmen/search';
export const API_ROUTE_GET_ALL_COMPANIES = 'unternehmen';
export const API_ROUTE_SEARCH_BERATER = 'kundenberater/search';
export const API_ROUTE_GET_BERATER = 'kundenberater';
export const API_ROUTE_CREATE_CUSTOMER = 'account/register';

export function getApiUrl(apiUrl: string, route: string) {
  return `${apiUrl}/v1/ikp/berater/${route}`;
}

export function getCustomerRegistrationUrl(route: string) {
  return `${ROUTE_CUSTOMER_REGISTRATION}/${route}`;
}
